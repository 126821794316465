import { formatDate } from './businessPartnerMasterDetailTables';

export const usersMasterDetailTables = {
  user_branches: {
    title: 'Branches',
    ajaxURL:
      '/api/organisation-branches/?format=json&fields=id,name,email_address,phone_number,url',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Email', field: 'email_address' },
      { title: 'Phone', field: 'phone_number' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.url}">View</a>`;
        },
      },
    ],
  },
  user_roles: {
    title: 'Roles',
    ajaxURL: '/api/roles/?format=json&fields=id,name,description',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
    ],
  },
  user_zones: {
    title: 'Roles',
    ajaxURL: '/api/territory_users/',
    columns: [
      { title: 'Name', field: 'territory_name' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.territory_url}">View</a>`;
        },
      },
    ],
  },
  user_staff: {
    title: 'Staff',
    ajaxURL: '/api/user_staff/',
    columns: [
      { title: 'Name', field: 'user_name' },
      { title: 'Email', field: 'user_email' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.user_url}">View</a>`;
        },
      },
    ],
  },
  staff_user: {
    title: 'Staff',
    ajaxURL: '/api/user_staff/',
    columns: [
      { title: 'Name', field: 'manager_name' },
      { title: 'Email', field: 'manager_email' },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.manager_url}">View</a>`;
        },
      },
    ],
  },
  user_document_attachment: {
    title: 'User Document Attachment',
    ajaxURL:
      '/api/staff-document-attachment/?format=json&fields=id,title,description,content_type',
    columns: [
      { title: 'Document Name', field: 'title' },
      {
        title: 'Description',
        field: 'description',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Type',
        field: 'content_type',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Download',
        formatter: function (cell) {
          return `<a href="/staff/download_staff_attachment/${
            cell.getRow().getData().id
          }"><i class="fa fa-download" aria-hidden="true"></i></a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          return `<a href="/staff/update_staff_document/${
            cell.getRow().getData().id
          }"><i class="fas fa-edit"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sorter: false,
        formatter: function (cell) {
          return `<a href="/staff/delete_staff_document/${
            cell.getRow().getData().id
          }"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>`;
        },
      },
    ],
  },
  user_transition_history: {
    title: 'User Transition History',
    ajaxURL:
      '/api/user-transition-logs/?format=json&fields=status_from,status_to,user_name,note,created',
    columns: [
      {
        title: 'Create At',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().created;
          let formattedDate = formatDate(rowData);
          return `<span class="d-block">${formattedDate}</span>`;
        },
      },
      {
        title: 'Status From',
        field: 'status_from',
        formatter: function (cell) {
          return (
            cell.getValue().charAt(0).toUpperCase() +
            cell.getValue().slice(1).toLowerCase()
          );
        },
      },
      {
        title: 'Status To',
        field: 'status_to',
        formatter: function (cell) {
          return (
            cell.getValue().charAt(0).toUpperCase() +
            cell.getValue().slice(1).toLowerCase()
          );
        },
      },
      {
        title: 'Created By',
        field: 'user_name',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Notes',
        field: 'note',
      },
    ],
  },
};
