/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const zonesList = module => {
  const basePath = `/navigation/${module.toLowerCase()}`;
  return {
    title: 'Territories',
    ajaxURL:
      '/api/territories/?format=json&fields=id,name,description,parent,url',
    addURLs: [],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Parent',
        formatter: function (cell) {
          if (cell.getRow().getData().parent) {
            return cell.getRow().getData().parent.name;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'View',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success px-3"
            href="${basePath}/territory/${cell.getRow().getData().id}/"
            >
            View
            </a>`;
        },
      },
    ],
  };
};

/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const zoneSalespeopleList = module => {
  const basePath = `/navigation/${module.toLowerCase()}/territory`;
  return {
    title: 'Staff',
    ajaxURL: '/api/territory_users/?format=json&fields=id,user,url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_name;
        },
      },
      {
        title: 'Email',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_email;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="ml-3 btn btn-sm btn-danger"
            style="font-size:12px;"
            href="${basePath}/remove-user/${rowData.id}/">
            <i class="fa-solid fa-trash" aria-hidden="true"></i>
            <span class="ms-2 d-none d-md-inline">Remove Salesperson</span>
            `;
        },
      },
    ],
  };
};

/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const zoneStaffList = module => {
  const basePath = `/navigation/${module.toLowerCase()}/territory`;
  return {
    title: 'Staff',
    ajaxURL: '/api/territory_users/?format=json&fields=id,user,url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_name;
        },
      },
      {
        title: 'Email',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_email;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="ml-3 btn btn-sm btn-danger"
            style="font-size:12px;"
            href="${basePath}/remove-user/${rowData.id}/">
            <i class="fa-solid fa-trash" aria-hidden="true"></i>
            <span class="ms-2 d-none d-md-inline">Remove Staff</span>
            `;
        },
      },
    ],
  };
};
