import {
  userBranchesList,
  userKYCList,
  usersList,
  userStaffList,
  userZonesList,
} from './usersHelpers';

/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const usersListProps = {
  setup_users: usersList('Setup'),
};

/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 **/
export const usersMasterDetailProps = {
  // Setup
  setup_users_branches: userBranchesList(),
  setup_users_kyc: userKYCList('Setup'),
  setup_users_zones: userZonesList('Setup'),
  setup_users_staff: userStaffList('Setup'),
};
