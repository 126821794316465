export const deliveryOrderMasterDetailTables = {
  delivery_order_items: {
    title: 'Delivery Order Line Items',
    ajaxURL: `
      /api/delivery-order-lines/data_table/?format=json&fields=id,order_status,sku,class_of_service,quantity,delivered_quantity,weight_uom,weight_uom,total_weight,volume_uom,volume
    `,
    columns: [
      {
        title: 'SKU',
        field: 'sku',
      },
      {
        title: 'Class Of Service',
        field: 'class_of_service',
      },
      {
        title: 'Ordered Quantity',
        field: 'quantity',
      },
      {
        title: 'Delivered Quantity',
        field: 'delivered_quantity',
      },
      {
        title: 'Weight',
        formatter: function (cell) {
          let uom = cell.getRow().getData().weight_uom;
          let weight = cell.getRow().getData().total_weight;
          return `${weight} ${uom}`;
        },
      },
      {
        title: 'Volume',
        formatter: function (cell) {
          let uom = cell.getRow().getData().volume_uom;
          let volume = cell.getRow().getData().volume;
          return `${volume} ${uom}`;
        },
      },
      {
        title: 'Unit Price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().price;
          return `${rowData.unit_price_before_tax} ${rowData.currency}`;
        },
      },
      {
        title: 'Tax',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().price;
          return `${rowData.total_tax_amount} ${rowData.currency}`;
        },
      },
      {
        title: 'Discount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().price;
          return `${rowData.total_discount_amount} ${rowData.currency}`;
        },
      },
      {
        title: 'Total Price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().price;
          return `${rowData.total_amount} ${rowData.currency}`;
        },
      },
      {
        title: 'Price Logs',
        formatter: function (cell) {
          return `<a href="/navigation/logistics/delivery-order-line-price-logs/${
            cell.getRow().getData().id
          }/">Price Log</a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let orderStatus = cell.getRow().getData().order_status;
          let isDraft = orderStatus === 'DRAFT' || orderStatus === 'READY';
          let href = isDraft
            ? `/navigation/logistics/delivery-orders/edit/order-line/${cell.getRow().getData().id}/`
            : '#';
          return isDraft
            ? `<a href="${href}"><i class="fas fa-edit"></i></a>`
            : `<a onclick="return false;" href="${href}"><i class="fas fa-edit text-muted"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sorter: false,
        formatter: function (cell) {
          let orderStatus = cell.getRow().getData().order_status;
          let isDraft = orderStatus === 'DRAFT';
          let href = isDraft
            ? `/navigation/logistics/delivery-orders/delete/order-line/${cell.getRow().getData().id}/`
            : '#';
          return isDraft
            ? `<a href="${href}"><i class="fa fa-trash text-danger"></i></a>`
            : `<a onclick="return false;" href="${href}"><i class="fa fa-trash text-muted"></i></a>`;
        },
      },
    ],
  },
  delivery_order_history: {
    title: 'Delivery Order History',
    ajaxURL:
      '/api/delivery-order-logs/?format=json&fields=note,user_name,formated_statuses,hf_created',
    columns: [
      {
        title: 'Created',
        field: 'hf_created',
      },
      {
        title: 'Status From',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_from;
        },
      },
      {
        title: 'Status To',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_to;
        },
      },
      {
        title: 'User',
        field: 'user_name',
      },
      {
        title: 'Note',
        field: 'note',
      },
    ],
  },
  delivery_order_shipments: {
    title: 'Shipments',
    ajaxURL:
      '/api/shipment/data-table/?fields=id,document_identifier,status,origin,destination,dispatch_branch_name,receiving_branch_name,hf_created',
    addURLs: [],
    exportURL: '/logistics/export_shipment/',
    columns: [
      {
        title: 'Id',
        formatter: function (cell) {
          let data = cell.getRow().getData();
          return `
          <span class="d-block">${data.document_identifier}</span>
            <span class="d-block text-muted">${data.hf_created}</span>
          `;
        },
      },
      { title: 'Status', field: 'status' },
      {
        title: 'Receiving Branch',
        formatter: function (cell) {
          let data = cell.getRow().getData();
          return `
          <span class="d-block">${data.receiving_branch_name ? data.receiving_branch_name : '-'}</span>
          `;
        },
      },
      { title: 'From', field: 'origin' },
      { title: 'To', field: 'destination' },
      {
        title: 'Action',
        formatter: function (cell) {
          return `
          <a class="btn btn-sm btn-success" href="/navigation/logistics/shipments/${cell.getRow().getData().id}/">View</a>
          `;
        },
      },
    ],
  },
  delivery_order_price_logs: {
    title: 'Price Logs',
    ajaxURL:
      '/api/price-logs/?format=json&fields=id,unit_price_before_tax,total_tax_amount,total_discount_amount,total_amount,currency,updated,user_name',
    addURLs: [],
    exportURL: '',
    columns: [
      {
        title: 'Created By',
        field: 'updated_by',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let userName = rowData.user_name ? rowData.user_name : null;
          return `${userName}`;
        },
      },
      {
        title: 'Date',
        field: 'updated',
      },

      {
        title: 'Tax Amount',
        field: 'total_tax_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let taxAmount = rowData.total_tax_amount;
          return `${taxAmount} ${rowData.currency}`;
        },
      },

      {
        title: 'Total Amount',
        field: 'total_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let TotalPrice = rowData.total_amount;
          return `${TotalPrice} ${rowData.currency}`;
        },
      },
    ],
  },
};
