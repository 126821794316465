import {
  partnerKycList,
  partnerLocationContactList,
  partnerPaymentList,
  partnersContactAddressList,
  partnersContactPersonList,
  partnersList,
  partnersLocationsList,
} from './partnersHelpers';

/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 * - Active tab
 * - Partner type
 * - API namespace
 * - Supplier (Boolean: Optional). Defaults to false
 **/
export const partnersListProps = {
  logistics_customers: partnersList(
    'Logistics',
    'Customers',
    'customer',
    'customers',
    false,
  ),
  logistics_transporters: partnersList(
    'Logistics',
    'Transporters',
    'supplier',
    'third-party-logistics',
    true,
  ),
};

/**
 * Reuse partner contact persons data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnersMasterDetailProps = {
  // Logistics: Customer
  logistics_customers_locations: partnersLocationsList(
    'Logistics',
    'Customers',
  ),
  logistics_customers_contact_persons: partnersContactPersonList(
    'Logistics',
    'Customers',
  ),
  logistics_customers_contact_addresses: partnersContactAddressList(
    'Logistics',
    'Customers',
  ),
  logistics_customers_kyc: partnerKycList('Logistics', 'Customers'),
  logistics_customers_payment_terms: partnerPaymentList(
    'Logistics',
    'Customers',
  ),
  logistics_customers_location_contact: partnerLocationContactList(
    'Logistics',
    'Customers',
  ),
  // Logistics: Transporters
  logistics_transporters_locations: partnersLocationsList(
    'Logistics',
    'Transporters',
  ),
  logistics_transporters_contact_persons: partnersContactPersonList(
    'Logistics',
    'Transporters',
  ),
  logistics_transporters_contact_addresses: partnersContactAddressList(
    'Logistics',
    'Transporters',
  ),
  logistics_transporters_kyc: partnerKycList('Logistics', 'Transporters'),
  logistics_transporters_payment_terms: partnerPaymentList(
    'Logistics',
    'Transporters',
  ),
  logistics_transporters_location_contact: partnerLocationContactList(
    'Logistics',
    'Transporters',
  ),
};
