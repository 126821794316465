export const shipmentMasterDetailTables = {
  shipment_items: {
    title: 'Shipment Line Items',
    ajaxURL:
      '/api/shipment-line-item/data-table/?format=json&fields=sku_name,quantity,delivered_quantity,weight_uom_name,total_weight,volume_uom_name,volume,total_weight',
    columns: [
      {
        title: 'SKU',
        formatter: function (cell) {
          let skuName = cell.getRow().getData().sku_name;
          return `${skuName}`;
        },
      },
      {
        title: 'Quantity',
        field: 'quantity',
      },
      {
        title: 'Delivered Quantity',
        field: 'delivered_quantity',
      },
      {
        title: 'Weight',
        formatter: function (cell) {
          let uom = cell.getRow().getData().weight_uom_name;
          let weight = cell.getRow().getData().total_weight;
          return `${weight} ${uom}`;
        },
      },
      {
        title: 'Volume',
        formatter: function (cell) {
          let uom = cell.getRow().getData().volume_uom_name;
          let volume = cell.getRow().getData().volume;
          return `${volume} ${uom}`;
        },
      },
    ],
  },
  shipment_history: {
    title: 'Shipment History',
    ajaxURL:
      '/api/shipment-logs/?format=json&fields=note,user_name,formated_statuses,hf_created',
    columns: [
      {
        title: 'Created',
        field: 'hf_created',
      },
      {
        title: 'Status From',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_from;
        },
      },
      {
        title: 'Status To',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_to;
        },
      },
      {
        title: 'User',
        field: 'user_name',
      },
      {
        title: 'Note',
        field: 'note',
      },
    ],
  },
};
