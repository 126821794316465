import { zoneSalespeopleList, zonesList, zoneStaffList } from './zonesHelpers';

/**
 * Reuse zones data table list props
 * Format:
 * - Current module
 **/
export const zonesListProps = {
  setup_zones: zonesList('Setup'),
};

/**
 * Reuse zones data table list props
 * Format:
 * - Current module
 **/
export const zonesMasterDetailProps = {
  // Setup
  setup_zones_salespeople: zoneSalespeopleList('Setup'),
  setup_zones_staff: zoneStaffList('Setup'),
};
