/**
 * Reuse partner data table list props
 * Format:
 * - Current module
 * - Active tab
 * - Partner type
 * - API namespace
 * - Supplier (Boolean: Optional). Defaults to false
 **/
export const partnersList = (
  module,
  navTab,
  partnerType,
  apiNameSpace,
  supplier = false,
) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}`;
  return {
    title: `${navTab}`,
    ajaxURL: `/api/${apiNameSpace.toLowerCase()}/?format=json&fields=id,partner_name,master_id,partner_type,supplier_segment,customer_segment,primary_phone_number,primary_email_address,url`,
    addURLs: [
      {
        name: 'Add Individual',
        url:
          navTab == 'Transporters'
            ? `${basePath}/create/${partnerType.toLowerCase()}/individual/?transporters`
            : `${basePath}/create/${partnerType.toLowerCase()}/individual/`,
      },
      {
        name: 'Add Corporate',
        url:
          navTab == 'Transporters'
            ? `${basePath}/create/${partnerType.toLowerCase()}/corporate/?transporters`
            : `${basePath}/create/${partnerType.toLowerCase()}/corporate/`,
      },
    ],
    exportURL: '/partners/export_customers',
    columns: [
      {
        title: 'Name',
        field: 'partner_name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return (
            `<span class="d-block">${rowData.partner_name}</span>` +
            `<span class="d-block text-muted">${rowData.master_id}</span>`
          );
        },
      },
      {
        title: 'Partner Type',
        field: 'partner_type',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let partner_type = rowData.partner_type
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');

          let segment;
          if (supplier) {
            segment = rowData.supplier_segment
              .split(' ')
              .map(
                word =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
              )
              .join(' ');
          } else {
            segment = rowData.customer_segment
              .split(' ')
              .map(
                word =>
                  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
              )
              .join(' ');
          }
          return (
            `<span class="d-block">${partner_type}</span>` +
            `<span class="d-block text-muted">${segment}</span>`
          );
        },
      },
      {
        title: 'Primary Phone',
        field: 'primary_phone_number',
      },
      {
        title: 'Primary Email',
        field: 'primary_email_address',
      },
      {
        title: 'View Details',
        field: 'url',
        formatter: function (cell) {
          return `<a
            href="${basePath}/${cell.getRow().getData().id}/"
            class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  };
};

/**
 * Reuse partner locations data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnersLocationsList = (module, navTab) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}`;
  return {
    title: 'Location Addresses',
    ajaxURL:
      '/api/business_partners_location_addresses/?format=json&fields=id,location_address,location_kind,department,primary_location',
    columns: [
      { title: 'Location', field: 'location_address' },
      { title: 'Location Category', field: 'location_kind' },
      {
        title: 'Department',
        sorter: false,
        formatter: function (cell) {
          if (cell.getRow().getData().department) {
            return cell.getRow().getData().department;
          } else {
            return `-`;
          }
        },
      },
      {
        title: 'Primary Location',
        sorter: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary_location === true) {
            return `<i class='fa-solid fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa-solid fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'View Details',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            href="${basePath}/location/${rowData.id}/"
            class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  };
};

/**
 * Reuse partner contact persons data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnersContactPersonList = (module, navTab) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}`;
  return {
    title: 'Contact Persons',
    ajaxURL:
      '/api/business_partners_contact_persons/?format=json&fields=id,name_read,phone_read,email_read,default_contact_person',
    columns: [
      { title: 'Name', field: 'name_read' },
      { title: 'Primary Phone', field: 'phone_read' },
      { title: 'Primary Email', field: 'email_read' },
      {
        title: 'Primary Contact Person',
        sorter: false,
        formatter: function (cell) {
          if (cell.getRow().getData().default_contact_person === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="${basePath}/update-contact-person/${rowData.id}/"><i class="fa-solid fa-pen-to-square text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (cell.getRow().getData().default_contact_person !== true) {
            return `
            <a href="${basePath}/delete-contact-person/${rowData.id}/"><i class="fa-solid fa-trash text-danger" aria-hidden="true"></i>  </a>
            `;
          } else {
            return '';
          }
        },
      },
    ],
  };
};

/**
 * Reuse partner contact persons data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnersContactAddressList = (module, navTab) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}`;
  return {
    title: 'Contact Addresses',
    ajaxURL:
      '/api/business_partners_contact_addresses/?format=json&fields=id,contact_value,contact_kind,primary_phone,primary_email',
    columns: [
      { title: 'Contact', field: 'contact_value' },
      { title: 'Contact Type', field: 'contact_kind' },
      {
        title: 'Primary Address',
        sorter: false,
        formatter: function (cell) {
          if (
            cell.getRow().getData().primary_phone ||
            cell.getRow().getData().primary_email
          ) {
            return `<i class='fa-solid fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa-solid fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            href="${basePath}/update-contact-address/${rowData.id}/">
            <i class="fas fa-edit text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (!rowData.primary_phone && !rowData.primary_email) {
            return `
            <a
            href="${basePath}/delete-contact-address/${rowData.id}/">
            <i class="fa-solid fa-trash text-danger" aria-hidden="true"></i></a>`;
          } else {
            return '';
          }
        },
      },
    ],
  };
};

/**
 * Reuse partner contact persons data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnerKycList = (module, navTab) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}`;
  return {
    title: 'Business Partner Documents',
    ajaxURL:
      '/api/business_partners_document_attachments/?format=json&fields=id,title,content_type',
    columns: [
      { title: 'Document Name', field: 'title' },
      {
        title: 'Type',
        field: 'content_type',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Download',
        formatter: function (cell) {
          let documentPK = cell.getRow().getData().id;
          return `<a href="/partners/download_business_partner_attachment/${documentPK}/"><i class="fa-solid fa-download" aria-hidden="true"></i></a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let documentPK = cell.getRow().getData().id;
          return `<a href="${basePath}/update-document/${documentPK}/"><i class="fa-solid fa-edit"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sorter: false,
        formatter: function (cell) {
          let documentPK = cell.getRow().getData().id;
          return `<a href="${basePath}/delete-document/${documentPK}/"><i class="fa-solid fa-trash" aria-hidden="true" style="color:red;"></i></a>`;
        },
      },
    ],
  };
};

/**
 * Reuse partner contact persons data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnerPaymentList = (module, navTab) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}`;
  return {
    title: 'Payment Term',
    ajaxURL:
      '/api/business_partners_payment_terms/?format=json&fields=id,payment_term_name,credit_days,payment_term_type,default_payment_term,closing_date',
    columns: [
      { title: 'Name', field: 'payment_term_name' },
      { title: 'Credit Days', field: 'credit_days_read' },
      {
        title: 'Closing Date',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().closing_date_read;
          return rowData ? rowData : `-`;
        },
      },
      { title: 'Payment Term', field: 'payment_term_type' },

      {
        title: 'Default Payment Term',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.default_payment_term === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return (
            '<a class="text-decoration-none" style="font-size:14px;"' +
            ` href="${basePath}/update-payment-term/${rowData.id}/"><i class="fas fa-edit text-primary" aria-hidden="true"></i></a>`
          );
        },
      },
    ],
  };
};

/**
 * Reuse partner contact persons data table list props
 * Format:
 * - Current module
 * - Active tab
 **/
export const partnerLocationContactList = (module, navTab) => {
  const basePath = `/navigation/${module.toLowerCase()}/${navTab.toLowerCase()}/location`;
  return {
    title: 'Location Contact Person',
    ajaxURL:
      '/api/business_partners_location_contact_person/?format=json&fields=id,contact_person,partner_location,primary_contact_person',
    columns: [
      {
        title: 'Contact Person',
        sorter: false,
        formatter: function (cell) {
          return cell.getRow().getData().contact_person.name;
        },
      },
      {
        title: 'Phone',
        sorter: false,
        formatter: function (cell) {
          return cell.getRow().getData().contact_person.phone;
        },
      },
      {
        title: 'Location Address',
        sorter: false,
        formatter: function (cell) {
          return cell.getRow().getData().partner_location.location.address;
        },
      },
      {
        title: 'Location Type',
        sorter: false,
        formatter: function (cell) {
          return cell.getRow().getData().partner_location.kind;
        },
      },
      {
        title: 'Primary Contact Person',
        sorter: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary_contact_person) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="${basePath}/update-contact/${rowData.id}/"><i class="fa-solid fa-edit text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (!rowData.primary_contact_person) {
            return `
            <a href="${basePath}/delete-contact/${rowData.id}/"><i class="fa-solid fa-trash text-danger" aria-hidden="true"></i></a>`;
          } else {
            return '';
          }
        },
      },
    ],
  };
};
